import Http from '@shared/helper/http-api'
import {
  type IPaymentTenantDetails,
} from '@shared/typings'
import {
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { type AppError } from 'single-spa'

const BASE_URL = process.env.BASE_URL
export const GET_PRODUCTS_LIST = 'marketplace/admin/product/get-all-products'
export const GET_REQUEST_DATA = 'marketplace/admin/payment/get-custom-request'

export const useGetProductsList = (): {
  data: any[] | undefined
  error: AppError | unknown
  isLoading: boolean
} => {
  const { data, error, isLoading } = useQuery<any[]>(
    GET_PRODUCTS_LIST,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(GET_PRODUCTS_LIST)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error, isLoading }
}

export const useAddPricing = (): any => {
  const queryClient = useQueryClient()

  return useMutation<any, any>(
    async (body) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>("marketplace/admin/payment/add-price", body)
    },
    {
      onSuccess () {
        void queryClient.invalidateQueries("FETCH_BLOG_COMMENTS")
      }
    }
  )
}

export const useGetRequestData = (request_id): {
  data: any | undefined
  error: AppError | unknown
  isLoading: boolean
} => {
  const { data, error, isLoading } = useQuery<any>(
    GET_REQUEST_DATA,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(GET_REQUEST_DATA + `/${request_id}`)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error, isLoading }
}

