import { useTheme } from '@mui/material'
import { CustomPageHeader } from '@shared/components/pageHeader/pageHeader'
import { Button, Grid, Input, Select, Typography } from '@ntpkunity/controls'
import { Controller, useForm } from 'react-hook-form'
import { validation } from '@shared/helper'
import { PaymentFrequency, PaymentMethodStatus, Validation } from '@shared/constants'
import { useEffect, useMemo, useState } from 'react'
import { useAddPricing, useGetProductsList, useGetRequestData } from './AddPackageListService'
import { useLocation, useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@shared/configuration'

export const AddPackageList = (props) => {

    const { state: locationState } = useLocation();
    const theme = useTheme()
    const formMethod = useForm()

    const navigate = useNavigate()
    const { data: ProductsList, isLoading: productsLoading } =
        useGetProductsList()
    const { data: requestData, isLoading: requestLoading } = useGetRequestData(locationState?.request_id)

    const [products, setProducts] = useState<any[]>([])
    const [request, setRequest] = useState<any>({})
    const { mutate: addPricing, isLoading: PricingLoading } = useAddPricing()

    const {
        control,
        handleSubmit,
        setValue,
        reset,
        formState: { errors }
    } = formMethod

    useEffect(() => {
        setProducts(ProductsList)
    }, [ProductsList])

    useEffect(() => {
        if (requestData) {
            setRequest(requestData)
            const { CustomPackageRequests: request } = requestData
            const formResetData = {
                "full_name": requestData?.name ?? "",
                "email": request?.email ?? "",
                "contact_number": request?.phone_number ?? "",
                "max_transactions_per_month": requestData?.allowed_transactions ?? 0,
                "product_id": request?.product_id ?? "",
                "status": request?.status ?? PaymentMethodStatus.PENDING,
                "payment_frequency": request?.payment_frequency ?? PaymentFrequency.MONTHLY,
                "pricing": requestData?.amount ?? 0
            }
            reset(formResetData)
        }
    }, [requestData])


    const messages = {
        name: {
            name: 'full_name',
            email: 'email',
            phone: 'contact_number',
            maxTransPerMon: 'max_transactions_per_month',
            productName: 'product_id',
            status: 'status',
            paymentMode: 'payment_frequency',
            pricing: 'pricing'
        },
        label: {
            name: 'Name',
            email: 'Email',
            phone: 'Contact Number',
            maxTransPerMon: 'Maximum Transactions per Month',
            productName: 'Product',
            status: 'Status',
            paymentMode: 'Payment Mode',
            pricing: 'Pricing'
        },
        button: {
            sumbit: 'Submit',
            wait: 'Wait...',
            cancel: 'Cancel',
            subscribe: 'Subscribe',
            unsubscribe: 'Unsubscribe',
            upgrade: 'Upgrade',
            downgrade: 'Downgrade'
        }
    }

    const productOptions = useMemo(
        () => products
            ?.sort((a, b) => a.name?.localeCompare(b.name))
            .reduce((agg, type) => [...agg, { text: type.name, value: type.id }], []),
        [products]
    )

    const statusOption = [
        { text: PaymentMethodStatus.PENDING, value: PaymentMethodStatus.PENDING },
        { text: PaymentMethodStatus.APPROVED, value: PaymentMethodStatus.APPROVED },
        { text: PaymentMethodStatus.DECLINED, value: PaymentMethodStatus.DECLINED },

    ]

    const paymentFrequencyOptions = [
        { text: PaymentFrequency.MONTHLY, value: PaymentFrequency.MONTHLY },
        { text: PaymentFrequency.QUARTERLY, value: PaymentFrequency.QUARTERLY },
        { text: 'Half-Yearly', value: PaymentFrequency.HALF_YEARLY },
        { text: PaymentFrequency.YEARLY, value: PaymentFrequency.YEARLY }
    ]

    const submit = (): void => {
        const formData = formMethod.watch()
        let data = {
            full_name: formData.full_name,
            email: formData.email,
            max_transactions_per_month: formData.max_transactions_per_month,
            contact_number: formData.contact_number,
            payment_frequency: formData.payment_frequency,
            product_id: formData.product_id,
            status: formData.status,
            request_id: request?.CustomPackageRequests?.id ?? null,
            price_id: request?.id ?? null,
            price_plan: {
                id: request?.id ?? null,
                name: formData.full_name,
                amount: formData.pricing,
                allowed_transactions: formData.max_transactions_per_month,
                product_id: formData.product_id,
                is_custom_package: true,
                custom_package_user_email: formData.email,
                description: formData.full_name,
                stripe_nick_name: formData.full_name,
                price_details: formData.pricing,
            }
        }

        addPricing(data, {
            onSuccess() {
                setRequest({})
                navigate(APP_ROUTES.CUSTOM_WORK_QUEUE.CUSTOM_WORK_QUEUE_LIST)
            }
        })


    }



    return (
        <>
            <CustomPageHeader
                theme={theme}
                variant='border'
                onBackBtn={() => { navigate(APP_ROUTES.CUSTOM_WORK_QUEUE.CUSTOM_WORK_QUEUE_LIST) }}
                title={
                    <>
                        {request?.CustomPackageRequests?.id ?
                            <Typography theme={theme} variant="h2" component="h2">
                                Custom Package
                            </Typography> :
                            <Typography theme={theme} variant="h2" component="h2">
                                Add Package
                            </Typography>
                        }
                    </>
                }
                actionArea={
                    <>
                        <Button disabled={PricingLoading} theme={theme} primary text="Save" type='submit' form='custom_package_form' />
                    </>
                }
            />
            <form id='custom_package_form' onSubmit={handleSubmit(submit)}>
                <Grid theme={theme} container item columnSpacing={3} rowSpacing={3.5} mt={0}>
                    <Grid theme={theme} item xs={12} md={3}>
                        <Controller
                            name={messages.name.name}
                            control={control}
                            rules={validation(
                                'Name',
                                Validation.REQUIRED,
                                Validation.WHITE_SPACE,
                                Validation.LIMIT_LENGTH,
                                '50',
                                Validation.ONLY_ALPHABETS
                            )}
                            render={({ field }) => (
                                <Input
                                    theme={theme}
                                    fullWidth
                                    label={messages.label.name}
                                    placeholder="Type here..."
                                    type={'text'}
                                    {...field}
                                    error={errors?.full_name?.message?.toString()}
                                />
                            )}
                        />
                    </Grid>
                    <Grid theme={theme} item xs={12} md={3}>
                        <Controller
                            name={messages.name.email}
                            control={control}
                            rules={validation(
                                messages.label.email,
                                Validation.REQUIRED,
                                Validation.EMAIL
                            )}
                            render={({ field }) => (
                                <Input
                                    theme={theme}
                                    fullWidth
                                    label={'Email Address'}
                                    type={'text'}
                                    placeholder="example@example.com"
                                    {...field}
                                    error={errors?.email?.message?.toString()}
                                />
                            )}
                        />
                    </Grid>
                    <Grid theme={theme} item xs={12} md={3}>
                        <Controller
                            name={messages.name.phone}
                            control={control}
                            rules={validation(
                                messages.label.phone,
                                Validation.PHNONE_NUMBER
                            )}
                            render={({ field }) => (
                                <Input
                                    theme={theme} label={messages.label.phone} placeholder="+000000000000"
                                    type={'number'} fullWidth
                                    {...field}
                                    error={errors?.contact_number?.message?.toString()}
                                />
                            )}
                        />
                    </Grid>
                    <Grid theme={theme} item xs={12} md={3}>
                        <Controller
                            name={messages.name.productName}
                            control={control}
                            defaultValue={''}
                            rules={validation(messages.label.productName, Validation.REQUIRED)}
                            render={({ field: { value, onChange } }) => (
                                <Select
                                    theme={theme}
                                    label={messages.label.productName}
                                    items={productOptions}
                                    sxProps={false}
                                    placeholder="Product"
                                    disablePortal={false}
                                    onChange={(e) => {
                                        onChange(e.target.value)
                                    }}
                                    value={value}
                                    selectError={errors?.product_id?.message?.toString()}
                                ></Select>
                            )}
                        />
                    </Grid>
                    <Grid theme={theme} item xs={12} md={3}>
                        <Controller
                            name={messages.name.maxTransPerMon}
                            control={control}
                            rules={validation(
                                messages.label.maxTransPerMon,
                                Validation.REQUIRED,
                                Validation.WHITE_SPACE,
                                Validation.LIMIT_LENGTH,
                                '50',
                                Validation.ONLY_NUMBERS
                            )}
                            render={({ field }) => (
                                <Input
                                    theme={theme} type='number' label={messages.label.maxTransPerMon} placeholder='Type here...' fullWidth
                                    {...field}
                                    error={errors?.max_transactions_per_month?.message?.toString()}
                                />
                            )}
                        />
                    </Grid>
                    <Grid theme={theme} item xs={12} md={3}>
                        <Controller
                            name={messages.name.status}
                            control={control}
                            defaultValue={PaymentMethodStatus.PENDING}
                            rules={validation(messages.label.status, Validation.REQUIRED)}
                            render={({ field: { value, onChange } }) => (
                                <Select
                                    theme={theme}
                                    label={messages.label.status}
                                    items={statusOption}
                                    sxProps={false}
                                    placeholder="Status"
                                    disablePortal={false}
                                    onChange={(e) => {
                                        onChange(e.target.value)
                                    }}
                                    value={value}
                                    selectError={errors?.status?.message?.toString()}
                                ></Select>
                            )}
                        />
                    </Grid>
                    <Grid theme={theme} item xs={12} md={3}>
                        <Controller
                            name={messages.name.paymentMode}
                            control={control}
                            defaultValue={PaymentFrequency.MONTHLY}
                            rules={validation(messages.label.paymentMode, Validation.REQUIRED)}
                            render={({ field: { value, onChange } }) => (
                                <Select
                                    theme={theme}
                                    label={messages.label.paymentMode}
                                    items={paymentFrequencyOptions}
                                    sxProps={false}
                                    placeholder="Payment Mode"
                                    disablePortal={false}
                                    onChange={(e) => {
                                        onChange(e.target.value)
                                    }}
                                    value={value}
                                    selectError={errors?.payment_frequency?.message?.toString()}
                                ></Select>
                            )}
                        />
                    </Grid>
                    <Grid theme={theme} item xs={12} md={3}>
                        <Controller
                            name={messages.name.pricing}
                            control={control}
                            rules={validation(
                                messages.label.pricing,
                                Validation.REQUIRED,
                                Validation.WHITE_SPACE,
                                Validation.LIMIT_LENGTH,
                                '50',
                                Validation.ONLY_NUMBERS
                            )}
                            render={({ field }) => (
                                <Input
                                    theme={theme} type='number' label={messages.label.pricing} startAdornment={'$'} placeholder='Type here...' fullWidth
                                    {...field}
                                    error={errors?.pricing?.message?.toString()}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </form>
        </>
    )
}
