import { useTheme } from '@mui/material'
import {} from './CustomWorkQueuePageHeaderStyle'
import { CustomPageHeader } from '@shared/components/pageHeader/pageHeader'
import { Button, Typography } from '@ntpkunity/controls'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@shared/configuration'

export const CustomWorkQueuePageHeaderComponent = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  return (
    <CustomPageHeader
      theme={theme}
      onBackBtn={()=>{}}
      title={
        <>
          <Typography theme={theme} variant="h2" component="h2">
            Work queue for custom package request
          </Typography>
        </>
      }
      actionArea={
        <>
          <Button theme={theme} primary text="Add Package" onClick={()=>{navigate(APP_ROUTES.ADD_PACKAGE.ADD_PACKAGE_LIST)}} />
        </>
      }
    />
  )
}
