import { getItem } from '@shared/configuration'
import { ACCESS_TOKEN_KEY } from '@shared/constants'
import {
  type DateTimeFormat,
  type IAWSFileUploadData,
  type IUploadMetaData,
  type IUploadParams
} from '@shared/typings'
import { useCallback, useContext, useEffect } from 'react'
import { type MutateOptions } from 'react-query'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'
import { type AppError } from './errors'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export const scrollOnTop = (): void => {
  window.scroll(0, 0)
}

export const openInNextWindow = (url: string): Window | null =>
  window.open(url)

export const loggedIn = (): boolean => {
  const AUTHENTICATION_TOKEN = getItem(ACCESS_TOKEN_KEY)
  return (AUTHENTICATION_TOKEN ?? '')?.length > 0
}

export const getInitials = (firstName: string, lastName: string): string => {
  const firstInitial = firstName[0]?.toUpperCase() ?? ''
  const lastInitial = lastName[0]?.toUpperCase() ?? ''

  return firstInitial + lastInitial
}

export const convertToDecimal = (amount: number): string => amount.toFixed()

export const formatedDate = (date: string | Date, format: string): string =>
  dayjs(date).format(format)

export const formatRelativeDateTime = (
  utcDateString: string
): DateTimeFormat => {
  const utcDate = dayjs.utc(utcDateString)
  const diffInMilliseconds = dayjs().diff(utcDate)
  const _24HrsInMilliseconds = 24 * 60 * 60 * 1000
  if (Math.abs(diffInMilliseconds) < _24HrsInMilliseconds) {
    return {
      date: '',
      time: dayjs(utcDate).fromNow()
    }
  } else {
    return {
      date: dayjs(utcDate).format('MMM DD, YYYY'),
      time: dayjs(utcDate).format('hh:mm A')
    }
  }
}

export const convertToDecimal2digit = (amount: number): string =>
  amount.toFixed(2)

export const convertDateFormat = (date) => {
  const formattedDate =
    date.slice(0, 2) + '/' + date.slice(2, 4) + '/' + date.slice(4)
  const parts = formattedDate.split('/')
  const year = parts[2]
  const month = parts[1].padStart(2, '0')
  const day = parts[0].padStart(2, '0')
  return `${year}-${month}-${day}`
}

export const useBlocker = (blocker, when = true) => {
  const navigatorContext = useContext(NavigationContext)
  const navigator: any = (navigatorContext as any)?.navigator
  useEffect(() => {
    localStorage.setItem('DirtyState', when.toString())
    if (!when) {
      return
    }
    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry () {
          unblock()
          tx.retry()
        }
      }
      blocker(autoUnblockingTx)
    })
    return unblock
  }, [navigator, blocker, when])
}

export const usePrompt = (message, when = true, callback, cancelCallback) => {
  const blocker = useCallback(
    (tx) => {
      // eslint-disable-next-line no-alert
      // if ( window.confirm( message ) ) tx.retry();'
      window.dispatchEvent(new Event('formState'))
      const result = callback(when)
      const test = cancelCallback(tx)
      // if (result){
      //     tx.retry();
      // }
      // return tx
    },
    [message, when]
  )
  useBlocker(blocker, when)
}

export const prepareString = (value: string): string => {
  value = value.replace(/(^\s*)|(\s*$)/gi, '') // exclude  start and end white-space
  value = value.replace(/[ ]{2,}/gi, ' ') // 2 or more space to 1
  value = value.replace(/\n /, '\n') // exclude newline with a start spacing
  return value
}

export const wordArrayInString = (value: string): string[] => {
  return prepareString(value)
    .split(' ')
    .filter(function (str) {
      return str != ''
    })
}

export const wordCount = (value: string): number => {
  return wordArrayInString(value).length
}

export const checkWordCount = (value: string, totalWords: number): boolean => {
  const _wordCount = wordCount(value)
  return _wordCount <= totalWords
}

export const fileMetadata = (file: File): IUploadMetaData => {
  return {
    name: file.name,
    size: file.size,
    type: file.type
  }
}

export const isEmpty = (value) => {
  let _isEmpty = true
  if (typeof value === 'object' && value !== null) {
    for (const key in value) {
      _isEmpty = _isEmpty && isEmpty(value[key])
    }
  } else {
    _isEmpty =
      value === undefined ||
      value === null ||
      value === '' ||
      (Array.isArray(value) && value.length === 0)
  }
  return _isEmpty
}

export const pick = <T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> => {
  return keys.reduce<Pick<T, K>>((accumulator: Pick<T, K>, key: K) => {
    if (obj.hasOwnProperty(key)) {
      accumulator[key] = obj[key]
    }
    return accumulator
  }, {} as Pick<T, K>)
}

export const omit = <T, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> => {
  const result = { ...obj } // start with a copy of the object
  keys.forEach((key) => delete result[key])
  return result
}

export const splitFeatureVariant = (variant: string): any[] =>
  variant.split(';')
export const S3FileKey = (s3FileUrl: string): string =>
  s3FileUrl?.split('.com/')[1]

export const uploadFileToS3Bucket = (
  uploadFile: (
    variables: any,
    options?: MutateOptions<IAWSFileUploadData, AppError, any, unknown>
  ) => void,
  { file, formFieldId, metadataId }: IUploadParams,
  deleteFile?: (formFieldId: any) => void
) => {
  const body = new FormData()
  body.append('files', file)

  const obj = {
    files: body,
    metadata: fileMetadata(file),
    formFieldId,
    metadataId
  }

  deleteFile?.(formFieldId)
  uploadFile(obj)
}

export const convertToAmount = (value: number): string =>
  value?.toLocaleString()

export const isValidUrl = (url: string): boolean => {
  const urlRegex = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?' + // port
      '(\\/[-a-z\\d%_.~+]*)*' + // path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i' // fragment locator
  )

  return urlRegex.test(url)
}

export const isDateValid = (date: Date): boolean => {
  return date instanceof Date && !isNaN(date.getTime())
}

export const formatCurrency = (
  number: number,
  locale = 'en-US',
  minimumFractionDigits = 2,
  includeCurrencySymbol = true
): string => {
  const options = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits
  }

  if (!includeCurrencySymbol) {
    options.style = 'decimal'
    options.currency = undefined
  }

  return number.toLocaleString(locale, options)
}
