import { TableCell, TableRow, useTheme } from '@mui/material'
import {
  Button,
  Checkbox,
  DataTable,
  Icon,
  ImageViewer,
  Menu,
} from '@ntpkunity/controls'

import { TableWrap } from './CustomWorkQueueTableStyle'
import { DataTableWrap } from '@shared/style/dataTableStyle'
import {
  useGetCustomQueue
} from '../../services/customWorkQueueService'
import { formatedDate } from '@shared/helper'
import {
  PaymentMethodAction,
  dateFormat,
  WorkQueueQueryTypes
} from '@shared/constants'
import { useEffect, useState } from 'react'
import { TableSkeleton } from '@shared/components/TableSkeleton/TableSkeleton'
import { type IPaymentTenantDetails } from '@shared/typings'
import { APP_ROUTES } from '@shared/configuration'
import { useNavigate } from 'react-router-dom'

const indicatorColor = {
  approved: 'ind-primary',
  declined: 'ind-danger',
  pending: 'ind-warning'
}

export const CustomWorkQueueTableComponent = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [selectedItem, setSelectedItem] = useState(null)
  const { data: customWorkqueue, isLoading: workQueueLoading } =
    useGetCustomQueue()



  const [filteredWorkQueue, setFilteredWorkQueue] =
    useState(customWorkqueue)
  const [filters, setFilters] = useState({
    tenantName: '',
    queryType: '',
    initiationDate: '',
    status: ''
  })
  const handleSelection = (
    key: string,
    paymentMethod: any
  ): void => {
    if(key === 'Edit'){
      navigate(APP_ROUTES.ADD_PACKAGE.ADD_PACKAGE_LIST, { state: { request_id: paymentMethod.id } });
    }
  }

  useEffect(() => {
    if (customWorkqueue) {
      const filteredData = customWorkqueue?.filter((item) => {
        return (
          // item.tenant_name
          //   .toLowerCase()
          //   .includes(filters.tenantName.trim().toLowerCase()) &&
          // `${item.query_type}-${item.payment_frequency}`
          //   .toLowerCase()
          //   .includes(filters.queryType.trim().toLowerCase()) &&
          // formatedDate(item.created_at, dateFormat)
          //   .toLowerCase()
          //   .includes(filters.initiationDate.trim().toLowerCase()) &&
          // item.status
          //   .toLowerCase()
          //   .includes(filters.status.trim().toLowerCase())
          true
        )
      })
      setFilteredWorkQueue(filteredData)
    }
  }, [filters, customWorkqueue])



  return (
    <DataTableWrap theme={theme} className="data-table-wrap">
      <TableWrap theme={theme} className="main-table-wrap">
        <span className="scroll-hide spr-border"></span>
        <DataTable
          theme={theme}
          variant="basic"
          theadChildren={
            <>
              <TableRow className="has-sorting">
                <TableCell className="checkbox-cell">
                  <Checkbox theme={theme} label={''} />
                </TableCell>
                <TableCell>
                  Email Address
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      iconText={<Icon name="ArrowUpDown" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Product(S)
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      iconText={<Icon name="ArrowUpDown" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Contact Number
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      iconText={<Icon name="ArrowUpDown" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Max Tran per Month
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      iconText={<Icon name="ArrowUpDown" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Status
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      iconText={<Icon name="ArrowUpDown" />}
                    />
                  </span>
                </TableCell>
                <TableCell className="action-cell"></TableCell>
              </TableRow>
              {/* <TableRow className="filters-row">
                <TableCell className="checkbox-cell"></TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                      value={filters.tenantName}
                      onChange={(e) => { handleInputChange(e, 'tenantName') }}
                    />
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                      value={filters.queryType}
                      onChange={(e) => { handleInputChange(e, 'queryType') }}
                    />
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                      value={filters.initiationDate}
                      onChange={(e) => { handleInputChange(e, 'initiationDate') }}
                    />
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                      value={filters.status}
                      onChange={(e) => { handleInputChange(e, 'status') }}
                    />
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell"></TableCell>
              </TableRow> */}
            </>
          }
          tbodyChildren={
            <>
              {workQueueLoading
                ? (
                <TableSkeleton theme={theme} columns={12} rows={4} />
                  )
                : (
                    filteredWorkQueue?.map((paymentMethod, key) => (
                  <TableRow className="child-tr" key={`paymentMethod-${key}`}>
                    <TableCell className="checkbox-cell">
                      <Checkbox theme={theme} label={''} />
                    </TableCell>
                    <TableCell>{paymentMethod.email}</TableCell>
                    <TableCell>{paymentMethod.product}</TableCell>
                    <TableCell>{paymentMethod.contact_number}</TableCell>
                    <TableCell>
                      {paymentMethod?.transaction_counts ?? 0}
                    </TableCell>
                    <TableCell>
                      <span
                        className={`status-indicator ${
                          indicatorColor[paymentMethod?.status?.toLowerCase()]
                        }`}
                      ></span>
                      {paymentMethod.status}
                    </TableCell>
                    
                    <TableCell className="action-cell fixed-cell">
                      <Menu
                        theme={theme}
                        options={[
                          
                          {
                            optionText: 'Edit',
                            optionkey: 'Edit',
                            optionValue: 'Edit',
                          },
                          {
                            optionText: 'Delete',
                            optionkey: 'Delete',
                            optionValue: 'Delete',
                          },
                          ...(paymentMethod.query_type ===
                          WorkQueueQueryTypes.PAYMENT_PROOF_STATUS_UPDATE
                            ? [
                                {
                                  optionText:
                                    PaymentMethodAction.PROOF_OF_PAYMENT,
                                  optionkey:
                                    PaymentMethodAction.PROOF_OF_PAYMENT,
                                  optionValue:
                                    PaymentMethodAction.PROOF_OF_PAYMENT,
                                  disabled:
                                    paymentMethod.document_keys.length === 0
                                }
                              ]
                            : [])
                        ]}
                        handleOptionClick={(e) => { handleSelection(e.target.innerText, paymentMethod) }
                        }
                        render={(cb) => (
                          <Button
                            defaultBtn
                            theme={theme}
                            iconText={<Icon name="MoreIcon" />}
                            onClick={cb}
                          ></Button>
                        )}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                      />
                    </TableCell>
                  </TableRow>
                    ))
                  )}
            </>
          }
        />
      </TableWrap>
    </DataTableWrap>
  )
}
